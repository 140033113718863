import { AGDIR_JOURNAL_FOLDER, AgdirFile, DIRECTORY_TYPE } from '@agdir/fillagring';
import { CompanyAssetsLegacyService } from '@agdir/services';
import { Inject, Injectable, Optional } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, firstValueFrom, map, Observable, switchMap, tap } from 'rxjs';
import { FILE_MANAGER_PREFIX } from '../index';
import { AgdirFileWithProgress } from './agdir-file-with-progress';

export type STORAGE_SIGNED_URL = string;
const API_URL = '/fillagring/{companyId}/' as string;

type Headers = Record<string, string>;

@Injectable({
	providedIn: 'root',
})
export class FileService {
	cache$ = new BehaviorSubject<Map<string, AgdirFile>>(new Map([]));

	constructor(
		private assetsService: CompanyAssetsLegacyService,
		@Optional() private translateService: TranslocoService,
		@Optional() @Inject(FILE_MANAGER_PREFIX) private fileManagerUrlPrefix = '',
	) {}

	addFMPrefix(path: string): string {
		const MFPrefix = window.location.pathname.match(new RegExp(this.fileManagerUrlPrefix))?.[0] || '';
		return `${MFPrefix}${path}`;
	}

	removeFMPrefix(path: string): string {
		return path.replace(new RegExp(`^${this.fileManagerUrlPrefix}`), '');
	}

	createFolder(name: string, parent: string): Observable<AgdirFile> {
		const sanitizedName = this.sanitizeName(name);
		const fullPath = parent.endsWith('/') ? `${parent}${sanitizedName}` : `${parent}/${sanitizedName}`;
		return this.assetsService.post<AgdirFile>(this.makeApiUrl(fullPath) + '/', {}).pipe(
			tap((newFolder) => {
				this.registerNewFileInCache({
					...(newFolder || {}),
					path: fullPath,
					name: sanitizedName,
					children: [],
					type: DIRECTORY_TYPE,
					size: 0,
				} as any);
			}),
		);
	}

	//
	getFileInformation(path: string): Observable<AgdirFile> {
		return this.assetsService.get<AgdirFile>(`${API_URL}${path}?info`).pipe(
			map((file) => ({
				...file,
				...(this.cache$.value.get(path) ?? {}),
			})),
		);
	}

	listFiles(path = '/', useCache = true): Observable<AgdirFile[]> {
		const pathWithoutQuery = path.split('?')[0];
		const url = pathWithoutQuery.endsWith('/') ? pathWithoutQuery : pathWithoutQuery + '/';
		if (this.cache$.value.has(url) && useCache) {
			return this.cache$.pipe(map((cache) => cache.get(url)?.children || []));
		} else {
			// Known issue - we dont have lazy load for now. This way it will be fast to navigate. We make lazy load if we need/want
			return this.fetchFiles('/').pipe(
				tap((newCache) => this.cache$.next(newCache)),
				switchMap(() => this.cache$.pipe(map((cache) => cache.get(url)?.children || []))),
			);
		}
	}

	getCurrentItem(path = '/'): Observable<AgdirFile> {
		return this.listFiles(path).pipe(map(() => this.cache$.value.get(path) as AgdirFile));
	}

	async rename(): Promise<void> {}

	//
	// deleteFile(path: string): Promise<S3.DeleteObjectOutput>;

	sanitizePath(path: string) {
		return `/${path.split('?')[0]}/`.replace(/\/\//g, '/').replace(/\/$/, '');
	}

	async download(path: string): Promise<string> {
		return firstValueFrom(this.assetsService.get<string>(this.makeApiUrl(path)));
	}

	async upload(file: File, folderName = '', customName?: string): Promise<AgdirFileWithProgress> {
		const headers: Headers = { 'Content-type': file.type };
		const fileWithStatus = new AgdirFileWithProgress(file, folderName, customName);
		const signedUploadUrl = await this.makeSignedUrl(fileWithStatus.path, headers);
		return this.putToS3(signedUploadUrl, file, fileWithStatus);
	}

	cacheS3AsAgdirFiles(s3Files: AgdirFile[]): Map<string, AgdirFile> {
		const cache = new Map<string, AgdirFile>([['/', { children: [] } as unknown as AgdirFile]]);
		s3Files.forEach((s3File) => {
			const parts = s3File.path
				.split('/')
				.filter((name) => name !== '')
				.slice(1); // first part is companyId
			parts.forEach((name, i) => {
				const isFolder = i < parts.length - 1 || s3File.path.endsWith('/');
				const partParentPath = ['', ...parts.slice(0, i), ''].join('/') || '/';
				const path = ['', ...parts.slice(0, i + 1), ...(isFolder ? [''] : [])].join('/');
				const copy: AgdirFile = {
					...s3File,
					type: isFolder ? DIRECTORY_TYPE : s3File.type,
					readOnly: s3File.path.includes(`/${AGDIR_JOURNAL_FOLDER}/`),
					name:
						isFolder && s3File.path.includes(`/${AGDIR_JOURNAL_FOLDER}/`) && isNaN(+name)
							? this.translateService.translate(`file-manager.journalFolders.${name}`)
							: name,
					path,
					...(isFolder ? { children: [] } : {}),
				};
				if (!cache.has(path)) {
					cache.set(path, copy);
				}
				if (!cache.get(partParentPath)?.children?.find((f) => f.name === name)) {
					cache.get(partParentPath)?.children?.push(copy);
				}
				if (cache.has(partParentPath)) {
					copy.parent = cache.get(partParentPath);
				}
			});
		});
		return cache;
	}

	//
	// updateFile(path: string, update: Pick<AgdirFile, 'uploadedBy' | 'notes' | 'name' | 'path'>): Promise<S3.PutObjectTaggingOutput>;
	//
	// moveFile(path: string, newName?: string, newPath?: string);
	//
	// moveFolder(path: string, newPath: string): Promise<S3.ListObjectsOutput>;

	registerNewFileInCache(agdirFile: AgdirFile) {
		const parentFolder = this.getParentPath(agdirFile);
		this.cache$.value.set(agdirFile.path, agdirFile);
		const parent = this.cache$.value.get(parentFolder);
		if (parent) {
			parent.children?.push(agdirFile);
			agdirFile.parent = parent;
		}
		this.cache$.next(this.cache$.value);
	}

	removeFileFromCache(agdirFile: AgdirFile) {
		const parentFolder = this.getParentPath(agdirFile);
		this.cache$.value.delete(agdirFile.path);
		const parent = this.cache$.value.get(parentFolder);
		if (parent) {
			parent?.children?.splice(
				parent?.children?.findIndex((f) => f.path === agdirFile.path),
				1,
			);
		}
		this.cache$.next(this.cache$.value);
	}

	getParentPath(file: Pick<AgdirFile, 'path'>): string {
		const withoutTrailingSlash = file.path.replace(/\/$/, '').split('/');
		withoutTrailingSlash.pop();
		return withoutTrailingSlash.join('/') + '/';
	}

	sanitizeFolderPath(path: string) {
		return this.sanitizePath(path) + '/';
	}

	async deleteFile(file: AgdirFile) {
		const remotePath = file.type === DIRECTORY_TYPE ? `${this.makeApiUrl(file.path)}/` : `${this.makeApiUrl(file.path)}`;
		await firstValueFrom(this.assetsService.delete<void>(remotePath));
		await this.removeFileFromCache(file);
	}

	private fetchFiles(path: string): Observable<Map<string, AgdirFile>> {
		return this.assetsService.get<AgdirFile[]>(this.makeApiUrl(path)).pipe(map((list) => this.cacheS3AsAgdirFiles(list)));
	}

	private sanitizeName(name = ''): string {
		return String(name).replace(/\//g, '');
	}

	private makeApiUrl(path: string = ''): string {
		return this.removeFMPrefix(API_URL.replace(/\/$/, '') + this.sanitizePath(path));
	}

	private makeSignedUrl(path: string, headers: Headers): Promise<STORAGE_SIGNED_URL> {
		return firstValueFrom(this.assetsService.post<STORAGE_SIGNED_URL>(this.makeApiUrl(path), null, { headers }));
	}

	// Fetch does not support (now) progress/ but we want progress. we are humans.
	private putToS3(signedUploadUrl: STORAGE_SIGNED_URL, file: File, fileWithStatus: AgdirFileWithProgress): Promise<AgdirFileWithProgress> {
		const data = new Blob([file], { type: file.type });

		return new Promise((resolve) => {
			const request = new XMLHttpRequest();
			request.open('PUT', signedUploadUrl);
			request.setRequestHeader('content-type', file.type);
			request.upload.addEventListener('progress', function (e) {
				fileWithStatus.progress.next((e.loaded / e.total) * 100);
			});
			request.addEventListener('load', function () {
				fileWithStatus.uploadedAt = new Date().toISOString();
				fileWithStatus.done.next(true);
			});
			request.send(data);
			resolve(fileWithStatus);
		});
	}
}
